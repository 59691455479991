<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Marking comments as read</h3>

	<p>Whenever you are signed in and viewing a framework for which you are a member of a <span v-html="link('comment_groups', 'comment group')"></span>, the system checks the server periodically (approximately every 15 seconds) to see if any new comments have been posted to the group. If a new or edited comment is found, this message will appear at the bottom of your window for a few seconds:</p>
	<img alt="Marked as read comment example" srcset="/docimages/comment_mark_as_read-1.png 3x" class="k-help-img block">
	<p>When you first view a new comment from another group member, it will have a <v-icon small>fas fa-exclamation-circle</v-icon> icon in the upper-right corner of the comment:</p>
	<img alt="First viewed comment example" srcset="/docimages/comment_mark_as_read-2.png 2x" class="k-help-img block">
	<p>Click the <v-icon small>fas fa-exclamation-circle</v-icon> icon to mark the comment as read (the icon will then disappear).</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	